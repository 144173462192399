.b-input {
  padding: 15px 16px !important;
  background: #F8F8F7 !important;
  border: 1px solid #F8F8F7 !important;
  border-radius: 8px !important;
  color: #000 !important;
  align-items: center;

  input {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .ant-input-group-addon {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  &:focus, &:hover, &:focus-within {
    border-color: #66A5AD !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: #000 !important;
    opacity: .4 !important;
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
    border-color: #ff4d4f !important;
  }

  &-password {
    padding: 15px 16px !important;
    background: #F8F8F7 !important;
    border: 1px solid #F8F8F7 !important;
    border-radius: 8px !important;
    color: #000 !important;
    box-shadow: none !important;

    input {
      background: transparent !important;
    }

    &:focus, &:hover, &.ant-input-affix-wrapper-focused {
      border-color: #66A5AD !important;
      box-shadow: none !important;
    }

    input::placeholder {
      color: #000 !important;
      opacity: .4 !important;
    }

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
      border-color: #ff4d4f !important;
    }
  }
}

.b-textarea {
  padding: 15px 16px !important;
  background: #F8F8F7 !important;
  border: 1px solid #F8F8F7 !important;
  border-radius: 8px !important;
  color: #000 !important;
  align-items: center;
  resize: none !important;

  &:focus, &:hover, &:focus-within {
    border-color: #66A5AD !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: #000 !important;
    opacity: .4 !important;
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
    border-color: #ff4d4f !important;
  }
}
