@import "_variables";

.b-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: calc(100% - 40px);

  @media (min-width: 768px) {
    height: calc(100% - 56px);
  }

  &__inner {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    height: 0;
    position: relative;
    overflow-y: auto;
  }

  &__list {
    padding-top: 18px;
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;

    &--me {
      justify-content: flex-end;
      .b-message__item {
        flex-direction: row-reverse;

        .b-message__text {
          border-radius: 8px 8px 0px 8px;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    justify-content: flex-start;
    position: revert;
    width: 100%;
    max-width: 85%;

    .date {
      position: absolute;
      top: -17px;
      left: 0;
      color: #66A5AD;
      @include rem(13);
      font-style: normal;
      font-weight: 500;
      line-height: 123.077%;
    }

  }

  &__avatar {
    width: 36px;
    min-width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    background: lightgray 50%;
    box-shadow: 0px 8px 16px 0px rgba(102, 165, 173, 0.32);

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__text {
    background: #DFF5FB;
    padding: 8px 16px;
    border-radius: 8px 8px 8px 0px;
    position: relative;
  }

  &__form {
    height: 72px;
    width: 100%;
    border-radius: 48px;
    border: 1px solid #6FB98F;
    background: #FFF;
    display: flex;
    padding: 8px;
    align-items: center;

    textarea {
      resize: none;
      width: calc(100% - 40px);
      height: 24px;
      padding: 0 8px;
      border-radius: 0;
      background: transparent;
      border: none;
      outline: none;
      color: #66A5AD;
      @include rem(15);
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }

  &__btn {
    user-select: none;
    outline: none;
    border: none;
    text-decoration: none;
    width: 56px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: none;
    display: flex;
    height: 56px;
    padding: 8px ;
    justify-content: center;
    align-items: center;
    background-color: #6FB98F;
    background-image: url(/images/paper-plane-outline.svg);
    background-position: 50%;
    background-repeat: no-repeat;
  }

  &__upload-file {
    position: relative;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url(/images/attach.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    margin-right: 16px;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  &__microphone {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url(/images/mic-outline.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    margin-right: 16px;
  }
}
