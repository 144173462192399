.b-rate {
  display: inline-flex !important;
  gap: 4px;

  li {
    margin-inline-end: 0 !important;
    padding: 5px 0 0 !important;

    &.ant-rate-star-full span {
      color: #ffbd00 !important;
    }

    &.ant-rate-star-zero span {
      color: #c4dfe6 !important;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      &_title {
        color: #2C7873;
        @include rem(13);
        font-weight: 500;
        line-height: 120%;
        text-align: center;
      }
    }
  }
}
