.b-modal {
  .ant-modal-content {
    border-radius: 24px !important;
  }

  .ant-modal-close {
    height: 64px !important;
    width: 64px !important;
    border-radius: 50% !important;
    background: #fff !important;
    top: -32px !important;
    inset-inline-end: -32px !important;

    &:active, &:hover {
      background: #fff !important;
    }
  }

  .ant-modal-footer {
    margin: 0 !important;
  }

  &__content {
    p {
      margin: 16px 0;
    }
  }

  &__comment__content, &__report__content {
    display: flex;
    flex-direction: column;
    padding: 44px 40px;
    gap: 24px;
  }

  &__expert {
    &__title {
      color: #003B46;
      @include rem(20);
      font-style: normal;
      font-weight: 600;
      line-height: 133.333%;
    }

    &__button {
      width: 100%;

      button {
        width: 100% !important;
      }
    }

    &__inner {
      height: auto;
      max-height: 60vh;
      overflow-y: auto;

      & > div {
        display: flex;
        flex-direction: column;
        padding-right: 20px;

        & > * {
          width: 100%;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: 40px;
      gap: 24px;

      .title-h4 {
        color: #003B46;
        @include rem(16);
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        padding-bottom: 16px;
      }
    }
  }

  &__users-list__content {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 24px;
  }
}

.ant-modal-mask {
  background-color: rgba(0, 59, 70, 0.4) !important;
}

.ant-upload-list-item-name {
  max-width: 280px;
}

.ant-upload-list-item {
  &:hover {
    background-color: transparent !important;
  }
}
