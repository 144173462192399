.b-practice {
  &-cases {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-case {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: flex-start;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;
    }
  }
}
