.b-slider {
  padding-block: 7px !important;
  height: 16px !important;

  .ant-slider-rail {
    background-color: #E5E5E5 !important;
  }

  .ant-slider-track {
    background-color: #66A5AD !important;
  }

  .ant-slider-handle {
    width: 16px !important;
    height: 16px !important;

    &::before {
      width: 16px !important;
      height: 16px !important;
      inset-inline-start: 0 !important;
      inset-block-start: 0 !important;
    }

    &::after {
      width: 16px !important;
      height: 16px !important;
      background-color: #66A5AD !important;
      inset-inline-start: 0 !important;
      inset-block-start: 0 !important;
      box-shadow: none !important;
    }

    &:focus, &:hover {
      &::after {
        outline: none !important;
        box-shadow: 0 0 0 8px rgba(102, 165, 173, .2) !important;
      }
    }
  }
}
