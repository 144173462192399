.b-button {
  &__filled {
    background: #66A5AD !important;
    font-size: 15px !important;
    border-radius: 8px !important;
    height: 54px !important;
    box-shadow: 0px 2px 4px 0px rgba(102, 165, 173, 0.32) !important;

    &_yellow {
      background: #FFBD00 !important;
      border-color: #FFBD00 !important;
      color: #003B46 !important;
      font-size: 15px !important;
      border-radius: 8px !important;
      height: 54px !important;
      box-shadow: none !important;
      padding: 4px 24px !important;
    }

    &_square {
      width: 42px !important;
      height: 42px !important;
      background: #66A5AD !important;
      font-size: 15px !important;
      border-radius: 8px !important;
      box-shadow: 0px 2px 4px 0px rgba(102, 165, 173, 0.32) !important;
      position: absolute !important;
      right: -8px !important;
      bottom: -8px !important;
      cursor: pointer;
    }

    &.danger {
      background: #D93E5C !important;
      box-shadow: none !important;
    }
  }

  &__link {
    color: #66A5AD !important;
    font-size: 15px !important;
    height: auto !important;
    padding: 0 !important;

    &.danger {
      color: #D93E5C !important;
    }
  }

  &__outlined {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-color: #66A5AD !important;
    color: #66A5AD !important;
    font-size: 15px !important;
    border-radius: 8px !important;
    height: 54px !important;

    &.danger {
      border-color: #D93E5C !important;
      color: #D93E5C !important;
    }

    span {
      margin-inline-end: 0 !important;
      line-height: 15px !important;
    }
  }

  &__logout {
    width: 100%;
    height: 24px !important;
    color: #D93E5C !important;
    font-style: normal;
    font-weight: 600 !important;
    padding: 0 !important;
    font-size: 15px !important;
    justify-content: flex-start !important;
  }
}
