.b-switch {
  height: 24px !important;
  background: #F8F8F7 !important;

  .ant-switch-handle {
    height: 24px !important;
    width: 24px !important;
    top: 0 !important;

    &::before {
      box-shadow: none !important;
      background: #C4DFE6 !important;
      border-radius: 50% !important;
    }
  }

  &.ant-switch-checked {
    background: #F8F8F7 !important;

    .ant-switch-handle::before {
      background: #66A5AD !important;
    }
  }
}
