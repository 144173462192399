.card-detail {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__expert {
    border-block-end: 1px solid #C4DFE6;
    display: flex;
    gap: 16px;
    padding: 0 0 16px;
  }

  &__portrait {
    width: 140px;
    height: 140px;
    border-radius: 16px;
    border: 2px solid #FFF;
    background: lightgray 50%;
    box-shadow: 0 8px 16px 0 rgba(102, 165, 173, 0.32);
    overflow: hidden;

    &_small {
      width: 86px;
      height: 86px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 0 0;
  }

  &__actions {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
  }

  &__back {
    color: #6FB98F !important;
    padding: 0 !important;
    @include rem(18);
    font-weight: 600;
    line-height: 120%;
  }

  &__supervisor-comment {
    width: 100%;
    background: #E4F5FA;
    padding: 8px;
    border-radius: 0 8px 8px 8px;
    color: #66A5AD;
    @include rem(13);
    font-weight: 500;
    line-height: 120%;
  }

  &__report-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;

    & > div {
      width: 100%;
      color: #4E7C86;
      @include rem(13);
      font-weight: 500;
      line-height: 120%;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: flex-end;
    }

    &_divider {
      flex: 1;
      border-bottom: 1px solid #E4F5FA;
    }
  }

  &__comments {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_title {
      color: #6FB98F;
      @include rem(18);
      font-weight: 600;
      line-height: 150%;
    }

    &_item {
      padding: 16px;
      background: #E4F5FA;
      border-radius: 0 16px 16px 16px;
      color: #66A5AD;
      @include rem(13);
      font-weight: 500;
      line-height: 133.333%;
    }

    &_add {
      color: #FF8A00 !important;
      padding: 0 !important;
      @include rem(15);
      font-weight: 400;
      line-height: 160%;
    }
  }

  &__name {
    color: #003B46;
    @include rem(18);
    font-weight: 600;
    line-height: 150%;
  }

  &__info {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }

  &__subtitle {
    color: #003B46;
    @include rem(15);
    font-weight: 600;
    line-height: 133.333%;
  }

  &__cost {
    color: #6FB98F;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    span {
      color: #B7B7B7;
    }
  }

  &__date {
    color: #66A5AD;
    @include rem(12);
    font-style: normal;
    font-weight: 300;
    line-height: 116.667%;

    &.chosen {
      color: #D93E5C;
    }

    &.history {
      color: #C4C4C4 !important;
    }
  }

  &__desc {
    color: #66A5AD;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__more {
    text-decoration: none;
    color: #FF8A00 !important;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    display: inline-flex;
    align-items: center;
  }

  &__apply {
    user-select: none;
    outline: none !important;
    border: none !important;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px !important;
    background: #FFBD00 !important;
    box-shadow: none !important;
    display: flex;
    height: 54px !important;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    color: #003B46 !important;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    &:hover, &:active {
      color: #003B46 !important;
    }
  }

  &__decline {
    user-select: none;
    outline: none !important;
    border: none !important;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px !important;
    background: #D93E5C !important;
    box-shadow: none !important;
    display: flex;
    height: 54px !important;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    &:hover, &:active {
      color: #fff !important;
    }
  }

  &__filled {
    user-select: none;
    outline: none !important;
    border: none !important;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px !important;
    background: #66A5AD !important;
    box-shadow: none !important;
    display: flex;
    height: 54px !important;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    &:hover, &:active {
      color: #fff !important;
    }
  }

  &__header {
    display: flex;
    padding-bottom: 8px;
    align-items: center !important;
    gap: 16px;
    align-self: stretch;
    margin-block-end: 0 !important;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 16px;
    }

    &__name {
      overflow: hidden;
      color: #003B46;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include rem(18);
      font-style: normal;
      font-weight: 600;
      line-height: 133.333%;
    }

    &__price {
      color: #6FB98F;
      @include rem(15);
      font-style: normal;
      font-weight: 600;
      line-height: 120%;

      span {
        color: #B7B7B7;
      }
    }
  }

  &__title {
    color: #003B46;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
  }

  &__subtitle {
    color: #003B46;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  &__desc {
    color: #66A5AD;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__profile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    align-items: flex-start;
    border-top: 1px solid #C4DFE6;

    &_title {
      width: 100%;
      gap: 16px;
      display: flex;
      justify-content: space-between;

      div {
        @include rem(18);
        font-weight: 600;
        line-height: 150%;
        color: #6FB98F;

        &:first-child {
          color: #003B46;
        }
      }
    }

    &_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_item {
      display: flex;
      gap: 16px;
      justify-content: space-between;

      .card-detail__inner {
        justify-content: center;
      }

      .card-detail__name {
        color: #2C7873;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      text-decoration: none;
      color: #FF8A00 !important;
      @include rem(15);
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      display: inline-flex;
      align-items: center;
    }
  }
}
