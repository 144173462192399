html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b, u, i,  dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  font-size: 100%;
  font-family: var(--font);
}


a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  outline:none;
  text-decoration: none;
  color: #fff;
}

a:active, a:hover {
  outline-width: 0;
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
td, td img {
  vertical-align: top;
}
input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none;
}
input[type="text"], input[type="password"], textarea {
  padding: 0;
}
input[type="checkbox"] {
  vertical-align: bottom;
}
input[type="radio"] {
  vertical-align: text-bottom;
}
sub {
  vertical-align: sub;
  font-size: smaller;
}
sup {
  vertical-align: super;
  font-size: smaller;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display:block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

