@import "_variables";

.menu-mobile {
  background-color: #66A5AD;
  width: 100%;
  max-width: 430px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 9999;

  &__header {
    background-color: rgba(255, 255, 255, 0.20);
    display: flex;
    padding: 24px 16px;
    align-items: center;
    gap: 16px;

    &__avatar {
      box-shadow: 0px 2px 4px 0px rgba(102, 165, 173, 0.32);
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
    }

    &__nav {
      list-style:none;
      margin: 0;
      display: flex;
      gap: 8px;

      &__line {
        color: #C4C4C4;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      a {
        font-style: normal;
        font-weight: 600;
        @include rem(16);
        line-height: normal;
        color: $white;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &__body {
    flex: 1 0 auto;

  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: inherit;
    list-style:none;
    margin: 0;

    li {
      width: 100%;
      padding: 0 16px;

      &:last-child {
        a {
          border: none;
        }
      }

      &.chosen {
        padding: 0;
        a {
          border-color: #2C7873;
          padding-left: 16px;
          padding-right: 16px;
          color: #D93E5C;
        }
      }
    }

    a {
      width: 100%;
       font-style: normal;
       font-weight: 600;
       @include rem(16);
       line-height: normal;
       color: $white;
       display: inline-flex;
       align-items: center;
       text-decoration: none;
       padding: 16px 0;
      border-bottom: 1px solid rgba(252, 214, 70, 0.16);

      &.active {
        color: #FFBD00;
      }
     }
  }

  @media (max-width: 991px) {
    //display: block;
  }
}