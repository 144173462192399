.ant-collapse-header {
  padding: 12px 0 !important;
}

.ant-collapse-header-text {
  color: #003B46;
  @include rem(16);
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
}

.ant-collapse-content-box {
  padding: 12px 0 !important;
}
