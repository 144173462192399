@import "_variables";

html, body {
  height: 100%;
}

body{
  --font: var(--font-comfortaa), -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: var(--font);
  background-color: #ffffff;

  & * {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #C4DFE6;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2C7873;
      border-radius: 8px;
    }
  }
}

*::selection {
  background-color: #2C7873;
  color: #fff;
}

a {
  color: #66A5AD !important;
}

.b-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.b-content {
  flex: 1 0 auto;
  position: relative;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    padding-bottom: 80px;
  }
}

.b-inner{
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .b-inner {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .b-inner {
    max-width: 990px;
  }
}

@media (min-width: 1200px) {
  .b-inner {
    max-width: 1290px;
  }
}

.title-h1 {
  color: #2C7873;
  @include rem(18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 576px) {
    @include rem(28);
  }

  @media (min-width: 768px) {
    @include rem(40);
  }
}

.title-h2 {
  color: #6FB98F;
  @include rem(18);
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;

  @media (min-width: 768px) {
    @include rem(24);
  }
}

.title-h3 {
  color: #6FB98F;
  @include rem(18);
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}


.b-article {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  background: linear-gradient(147deg, #E4F5FA 1.06%, #FFF 100%);
  box-shadow: 0px 4px 8px 0px rgba(102, 165, 173, 0.16);

  &__image {
    width: 100%;
    height: 197px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__inner {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    flex: 1 0 auto;
    height: 122px;
  }

  &__title {
    color: #003B46;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #66A5AD;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
    flex: 1 0 auto;
  }

  &__link {
    color: #FF8A00 !important;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }
}

.b-popular {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__image {
    width: 100%;
    height: 200px;
    background: lightgray 50%;
    border-radius: 16px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    height: 46px;
  }

  &__title {
    overflow: hidden;
    color: #003B46;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
    width: 100%;
  }

  &__wrap-link {
    display: flex;
    gap: 4px;
    color: #C4DFE6;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  &__link {
    text-decoration: none;
    color: #2C7873;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}

//lang button
.b-lang-button {
  border-radius: 8px;
  background: #66A5AD;
  display: inline-flex;
  color: #fff;
  padding: 8px;
  gap: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}

//switcher
.base-switcher {
  position: relative;
  display: block;
  width: 48px;
  height: 24px;
  cursor: pointer;

  &__circle {
    background: #C4DFE6;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 0;
    left: 0;
    position: absolute;
    transition: left .25s ease,  background .25s ease;
  }

  &__line {
    background-color: #F8F8F7;
    border: none;
    border-radius: 24px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 24px;
  }

  &__input {
    opacity: 0;
    &:checked {
      + .base-switcher__line {

        + .base-switcher__circle {
          background: #66A5AD;
          left: 24px;
        }
      }
    }
  }
}

.b-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > div {
    width: 100%;
  }

  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title-h5 {
    color: #003B46;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .title-h4 {
    color: #003B46;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .title-h3 {
    color: #003B46;
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
  }

  .ant-collapse {
    margin-bottom: 16px;
  }

  .ant-collapse-ghost >.ant-collapse-item, .ant-collapse-item, .ant-collapse-item:last-child {
    border-bottom: 1px solid #C4DFE6 !important;
    border-radius: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-header {
    padding: 16px 0 !important;
    align-items: center !important;

    .ant-collapse-expand-icon {
      height: 32px !important;
      width: 32px;
      padding-inline-start: 0 !important;
      justify-content: center;
      color: #2C7873;
    }
  }

  &__content {
    height: 265px;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    // scrollbar-width: thin;
    // scrollbar-color: #2C7873 #C4DFE6;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #C4DFE6;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2C7873;
      border-radius: 8px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__range {
    width: 100%;
    margin-bottom: 16px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    width: 100%;
    gap: 16px;
  }

  &__slider {
    width: 100%;
    position: relative;
    margin: 0 0 0 -5px;
  }

  &__title {
    color: $black;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-transform: capitalize;
  }

  &__collapsed {
    &__title {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      color: #003B46;
    }

    &__desc {
      color: #6FB98F;
    }
  }
}

.base-btn {
  user-select: none;
  outline: none;
  border: none;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(252, 214, 70, 0.16);
  display: flex;
  gap: 10px;
  height: 54px;
  padding: 8px 31px;
  justify-content: center;
  align-items: center;
  @include rem(15);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  @media (max-width: 767px) {
    padding: 8px;
    height: 40px;
  }
}

.btn-edit {
  user-select: none;
  outline: none;
  border: none;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  background: #66A5AD;
  box-shadow: 0px 2px 4px 0px rgba(102, 165, 173, 0.32);
  display: flex;
  gap: 10px;
  height: 54px;
  padding: 8px 31px;
  justify-content: center;
  align-items: center;
  color: $white !important;
  @include rem(15);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.btn-add {
  user-select: none;
  outline: none;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  background: $white;
  border: 1px solid #66A5AD;
  box-shadow: none;
  display: flex;
  gap: 10px;
  height: 54px;
  padding: 8px 31px;
  justify-content: center;
  align-items: center;
  color: #66A5AD;
  @include rem(15);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.btn-cancel {
  user-select: none;
  outline: none;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  background: $white;
  border: 1px solid #D93E5C;
  box-shadow: none;
  display: flex;
  gap: 10px;
  height: 54px;
  padding: 8px 31px;
  justify-content: center;
  align-items: center;
  color: #D93E5C;
  @include rem(15);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.btn-apply {
  user-select: none;
  outline: none !important;
  border: none !important;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 8px !important;
  background: #FFBD00 !important;
  box-shadow: 0px 2px 4px 0px rgba(252, 214, 70, 0.16) !important;
  display: flex;
  gap: 10px;
  height: 54px !important;
  padding: 8px 31px;
  justify-content: center;
  align-items: center;
  color: #003B46 !important;
  @include rem(15);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  &:hover, &:active {
    color: #003B46 !important;
  }
}

.btn-disabled {
  opacity: .4 !important;
}

.btn-back {
  user-select: none;
  outline: none;
  border: none;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 8px !important;
  background: #2C7873 !important;
  box-shadow: 0px 2px 4px 0px rgba(252, 214, 70, 0.16) !important;
  display: flex !important;
  gap: 10px !important;
  height: 54px !important;
  padding: 8px 31px;
  justify-content: center;
  align-items: center !important;
  color: $white !important;
  @include rem(15);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  &__auto {
    width: auto;
  }
}

.btn-video {
  user-select: none;
  outline: none;
  border: none;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  background: #DFF5FB;
  box-shadow: 0px 2px 4px 0px rgba(252, 214, 70, 0.16);
  display: flex;
  gap: 10px;
  height: 54px;
  padding: 8px 31px;
  justify-content: center;
  align-items: center;
  color: #003B46;
  @include rem(15);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.card-profile {
  display: flex !important;
  flex-direction: column;
  gap: 8px !important;
  padding: 0 0 8px !important;
  border-block-end: 1px solid #C4DFE6 !important;
  margin: 0 0 16px !important;

  &.session {
    &__item {
      margin: 0 !important;
      cursor: pointer;
    }
  }

  &__header {
    display: flex;
    padding-bottom: 8px;
    align-items: center !important;
    gap: 16px;
    align-self: stretch;
    margin-block-end: 0 !important;

    .ant-list-item-meta-avatar {
      margin-inline-end: 0 !important;
    }

    &__portrait {
      width: 86px;
      height: 86px;
      border-radius: 16px;
      border: 2px solid #FFF;
      background: lightgray 50%;
      box-shadow: 0 8px 16px 0 rgba(102, 165, 173, 0.32);
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 16px;
      }
    }

    &__inner {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 1 0 0;
      justify-content: space-between;

      & > div {
        display: flex;
        gap: 4px;
        padding-left: 1px;

        &:first-child {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &__name {
      overflow: hidden;
      color: #003B46;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include rem(18);
      font-style: normal;
      font-weight: 600;
      line-height: 133.333%;
    }

    &__price {
      color: #6FB98F;
      @include rem(15);
      font-style: normal;
      font-weight: 600;
      line-height: 120%;

      span {
        color: #B7B7B7;
      }
    }
  }

  &__skills {}

  &__title {
    color: #003B46;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
  }

  &__subtitle {
    color: #003B46;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  &__desc {
    color: #66A5AD;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      text-decoration: none;
      color: #FF8A00 !important;
      @include rem(15);
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      display: inline-flex;
      align-items: center;
    }
  }
}

.skills__list {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  &__item {
    margin-inline-end: 0 !important;
    padding-inline: 4px !important;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    background: #2C7873 !important;
    border-color: #2C7873 !important;
    color: #fff !important;
    height: 22px;
    text-transform: capitalize;
  }

  &__more {
    margin-inline-end: 0 !important;
    padding-inline: 4px !important;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    border-color: #2C7873 !important;
    color: #2C7873 !important;
    height: 22px;
  }
}

.expert-card {
  position: relative;
  border-radius: 16px;
  background: #66A5AD;
  padding: 16px;
  flex-flow: wrap;
  display: flex;
  margin-bottom: 42px;
  top: 24px;

  &__avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $white;
    background: lightgray 50%;
    box-shadow: 0px 8px 16px 0px rgba(102, 165, 173, 0.32);

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 16px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 16px;
    margin-bottom: 16px;
  }

  &__wrap-btn {
    flex: 0 0 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;

    .btn-apply,
    .btn-video {
      @media (max-width: 767px) {
        padding: 8px;
        height: 40px;
      }
    }
  }

  &__title {
    color: $white;
    @include rem(18);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__info {
    color: $white;
    @include rem(13);
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    display: flex;
    flex-flow: column;
    gap: 8px;

    i {
      display: none;
      font-style: normal;
    }
  }

  &__rating {
    display: flex;
    gap: 8px;
    flex-flow: wrap;
    align-items: center;

    & > span {
      display: block;
      color: #2C7873;
      @include rem(13);
      font-style: normal;
      font-weight: 500;
      line-height: 123.077%;
      padding-top: 9px;
    }

    img {
      width: 120px;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
  }

  @media (min-width: 992px) {
    padding: 24px;
    flex-flow: nowrap;
    justify-content: space-between;

    &__avatar {
      width: 220px;
      height: 220px;
      position: absolute;
      top: -18px;
      left: 24px;
    }

    &__inner {
      padding-left: 244px;
      gap: 16px;
      margin-bottom: 0;
    }

    &__wrap-btn {
      flex: 0 0 auto;
      width: 168px;

      .btn-video {
        position: absolute;
        padding: 0;
        gap: 0;
        left: 24px;
        bottom: -32px;
        width: 64px;
        height: 64px;
        font-size: 0;
        color: transparent;
        border-radius: 50%;
        background: $white;
        box-shadow: 0px 2px 4px 0px rgba(102, 165, 173, 0.32);

        img {
          width: 32px;
          height: 32px;
          object-fit: cover;
        }

      }
    }

    &__title {
      @include rem(48);
    }

    &__info {
      @include rem(15);
      flex-flow: nowrap;
      gap: 16px;
      i {
        display: block;
      }
    }

    &__rating {
      @include rem(15);
      gap: 16px;

      img {
        width: 168px;
      }
    }
  }
}

.expert-info {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-top: 24px;

  .skills__list {
    align-self: initial;
  }
}

.base-text {
  color: #66A5AD;
  @include rem(13);
  font-style: normal;
  font-weight: 500;
  line-height: 123.077%;
  margin-bottom: 16px;
}


.offers-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.form-link {
  display: flex;
  justify-content: center;

  a {
    color: #003B46 !important;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.breadcrumb {
  margin-bottom: 16px;
  list-style: none;
  display: flex;
  align-items: center;
  flex-flow: wrap;

  .breadcrumb-item {
    color: #003B46;
    @include rem(18);
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;

    a {
      color: #2C7873;
    }

    +.breadcrumb-item {
      padding-left: 0.5rem;

      &:before {
        float: left;
        padding-right: 0.5rem;
        color: #6FB98F;
        content: "/";
      }
    }
  }
}





