.b {
  &-modal {
    &__auth {
      &__content {
        padding: 80px 76px 68px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: center;

        span, div {
          font-size: 13px;
        }

        & > span {
          color: #66A5AD;
        }
      }

      &__logo {
        margin-bottom: 16px;
      }

      &__agreement {

      }
    }
  }
}
