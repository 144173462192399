//font-size
@mixin rem($size) {
  font-size: $size/16*1rem;
}

.w-100 {
  width: 100% !important;
}


//color
$white: #ffffff;
$black: #000000;
