@import "_variables";

.form-field {
  position: relative;

  &.date,
  &.password-hide,
  &.password-show {
    input {
      padding-right: 32px !important;
    }
  }

  &.date {
    &:before {
      content: "";
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url(/images/calendar-clear-outline.svg);
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }

  &.password-hide {
    &:before {
      content: "";
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url(/images/eye-off.svg);
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }

  &.password-show {
    &:before {
      content: "";
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url(/images/eye-outline.svg);
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }
}

.form-fieldset {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  .ant-form-item {
    margin: 0 !important;
  }
}

.form-actions {
  display: flex;
  gap: 16px;
}

input {
  &.base-input {
    height: 54px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    border-radius: 8px;
    background: #F8F8F7;
    border: 1px solid #F8F8F7;
    outline: none;
    color: $black;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

  }
}

textarea {
  &.base-textarea {
    height: 120px;
    width: 100%;
    padding: 15px 16px;
    border-radius: 8px;
    background: #F8F8F7;
    border: 1px solid #F8F8F7;
    outline: none;
    color: $black;
    @include rem(15);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

  }
}

.user-avatar {
  display: grid;
  gap: 16px;
  align-items: center;
  margin-bottom: 4px;
  grid-template-columns: 100px auto;

  &__edit {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 16px;
    border: 2px solid #FFF;
    box-shadow: 0px 8px 16px 0px rgba(102, 165, 173, 0.32);
    background-image: url(/images/user-avatar.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      position: absolute;
      right: -8px;
      bottom: -8px;
      cursor: pointer;
      display: block;
      width: 42px;
      height: 42px;
      border-radius: 8px;
      background-color: #66A5AD;
      background-image: url(/images/camera.svg);
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }

  &__text {
    color: #66A5AD;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
  }
}
