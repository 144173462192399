@import "_variables";

.b-page {
  padding-top: 24px;

  &__back {
    margin-bottom: 16px;
  }

  .title-h2 {
    margin-bottom: 16px;
  }

  .title-h3 {
    margin-bottom: 8px;
  }

  .skills__list,
  .sertific {
    margin-bottom: 16px;
  }

  .wrap-btn-prise {
    display: flex;
    flex-flow: column;
    gap: 8px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #C4DFE6;
    margin-bottom: 24px;

    &__text {
      color: #6FB98F;
      @include rem(24);
      font-style: normal;
      font-weight: 500;
      line-height: 133.333%;

      span {
        color: #B7B7B7;
        @include rem(18);
        font-style: normal;
        font-weight: 600;
        line-height: 133.333%;
      }
    }
  }

  .case-list {
    .skills__list,
    .base-text {
      margin-bottom: 8px;
    }

    +.case-list {
      padding-top: 16px;
      border-top: 1px solid #C4DFE6;
    }
  }


  @media (min-width: 576px) {
    &__back {
      max-width: 245px;
    }

    .wrap-btn-prise {
      flex-flow: nowrap;
      gap: 16px;

      a {
        max-width: 300px;
      }
    }
  }

  @media (min-width: 768px) {
    &__back {
      margin-bottom: 24px;
    }

    .offers-list {
      margin-bottom: 72px;
    }
  }
}

.b-news {
  &__header {
    position: relative;
    padding-top: 24px;

    .b-inner {
      position: relative;
    }


    .title-h1 {
      text-align: center;
      margin-bottom: 16px;
    }

    .wrap-text {
      text-align: center;
      margin-bottom: 16px;

      p {
        color: #2C7873;
        @include rem(13);
        font-style: normal;
        font-weight: 500;
        line-height: 133.333%;

        @media (min-width: 576px) {
          @include rem(16);
        }

        @media (min-width: 768px) {
          @include rem(24);
        }

        @media (max-width: 1249px) {
          br {
            display: none;
          }
        }
      }
    }

    &__img {
      width: 398px;
      height: 368px;
      left: 50%;
      position: relative;
      transform: translateX(-50%);

      @media (max-width: 400px) {
        width: 100%;
        height: auto;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    @media (min-width: 768px) {
      padding-top: 38px;
    }

    @media (min-width: 992px) {
      padding-top: 0;
      min-height: 445px;
      .title-h1 {
        max-width: 580px;
        padding-top: 110px;
        margin-bottom: 32px;
      }

      .wrap-text {
        max-width: 555px;
        position: relative;

        &:before {
          content: "";
          width: 65px;
          height: 65px;
          background: #F5F944;
          filter: blur(6.5px);
          position: absolute;
          border-radius: 50%;
          z-index: 1;
          bottom: -103px;
          left: 32px;
        }
      }

      &__img {
        position: absolute;
        left: auto;
        right: 0;
        top: 24px;
        width: 474px;
        height: 465px;
        transform: none;
      }
    }

    @media (min-width: 1200px) {
      &:before {
        content: "";
        width: 28px;
        height: 28px;
        background: #4566E8;
        filter: blur(4px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        top: -65px;
        left: 160px;
      }
      &:after {
        width: 65px;
        height: 65px;
        background: #D280FF;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        top: -100px;
        right: 164px;
        content: "";
      }

      .title-h1 {
        max-width: 800px;
      }

      .wrap-text {
        max-width: 800px;
      }
    }
  }

  &__filter {
    position: relative;

    @media (min-width: 992px) {
      &:before {
        content: "";
        width: 65px;
        height: 65px;
        background: #F5F944;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        bottom: 15px;
        right: 16px;
      }
    }
  }

  .wrap-filter {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: relative;

    .filter-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 62px;
      border: 1px solid #003B46;
      height: 40px;
      padding: 8px 16px;
      color: #003B46 !important;
      @include rem(16);
      font-style: normal;
      font-weight: 700;
      line-height: 133.333%;
      text-decoration: none;
      transition: all .2s ease;

      &:hover,
      &.active {
        color: $white !important;
        background-color: #003B46;
      }
    }

    @media (min-width: 768px) {
      gap: 16px;

      .filter-item {
        height: 64px;
        padding: 16px 24px;
        @include rem(24);
      }
    }
  }

  &__result-list {
    position: relative;
    padding-top: 27px;
    overflow: hidden;

    @media (min-width: 768px) {
      padding-top: 42px;
      margin-bottom: 56px;
    }

    .b-inner {
      position: relative;

      @media (min-width: 1200px) {
        &:before {
          content: "";
          width: 51px;
          height: 51px;
          background: #EC8238;
          filter: blur(6.5px);
          position: absolute;
          border-radius: 50%;
          z-index: 1;
          top: 240px;
          left: -140px;
        }
        &:after {
          width: 65px;
          height: 65px;
          background: #D280FF;
          filter: blur(6.5px);
          position: absolute;
          border-radius: 50%;
          z-index: 1;
          top: 516px;
          right: -88px;
          content: "";
        }
      }
    }
  }


}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  @media (min-width: 992px) {
    gap: 24px;

    &:after {
      width: 65px;
      height: 65px;
      background: #D280FF;
      filter: blur(6.5px);
      position: absolute;
      border-radius: 50%;
      z-index: -1;
      bottom: 200px;
      left: -33px;
      content: "";
    }
  }
}

.news-item {
  padding-bottom: 16px;
  border-bottom: 1px solid #66A5AD;
  display: flex;
  gap: 16px;
  flex-flow: column;

  &:last-child {
    border-bottom: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__image {
    height: 214px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &__title {
    color: #2C7873;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
    margin-bottom: 16px;
  }

  &__badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    height: 24px;
    padding: 4px 8px;
    color: $white;
    background-color: #2C7873;
    @include rem(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__text {
    color: #003B46;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;

    @media (max-width: 400px) {
      flex-flow: wrap;
      gap: 8px;
    }

    &__author {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        display: block;
        overflow: hidden;
        border-radius: 50%;
        width: 48px;
        min-width: 48px;
        height: 48px;
        object-fit: cover;
      }

      &__inner {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    &__name {
      color: #2C7873;
      @include rem(16);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__date {
      color: #66A5AD;
      @include rem(14);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__counter {
      display: flex;
      gap: 16px;
    }

    &__share,
    &__like {
      display: flex;
      gap: 4px;
      align-items: center;
      color: #003B46;
      @include rem(18);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  @media (min-width: 768px) {
    flex-flow: nowrap;

    &__image {
      width: 300px;
      min-width: 300px;
      height: auto;
    }

    &__title,
    &__text {
      margin-bottom: 8px;
    }

    &__badge {
      margin-bottom: 8px;
    }
  }

  @media (min-width: 992px) {
    gap: 24px;
    flex-flow: nowrap;

    &__image {
      width: 380px;
      min-width: 380px;
    }

    &__title {
      @include rem(22);
      margin-bottom: 8px;
    }

    &__text {
      @include rem(16);
      -webkit-line-clamp: 4;
    }
  }
}

.b-news-page {
  position: relative;
  padding-bottom: 16px;
  padding-top: 24px;
  overflow: hidden;

  &__title {
    color: #2C7873;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
    margin-bottom: 16px;
  }

  .news-item__badge{
    margin-bottom: 16px;
  }

  &__text {
    color: #2C7873;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
    margin-bottom: 16px;
  }

  &__image {
    width: 100%;
    overflow: hidden;
    border-radius: 16px;
    height: 300px;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title-h2 {
    padding-top: 16px;
    margin-bottom: 16px;
  }

  &__inner {
    position: relative;

    @media (min-width: 1200px) {
      &:before {
        content: "";
        width: 65px;
        height: 65px;
        background: #F5F944;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        top: 535px;
        right: -190px;
      }
      &:after {
        width: 65px;
        height: 65px;
        background: #D280FF;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        top: 560px;
        left: -130px;
        content: "";
      }
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 65px;

    &__title {
      @include rem(32);
    }

    &__text {
      @include rem(16);
    }
  }

  @media (min-width: 1200px) {
    &:before {
      content: "";
      width: 28px;
      height: 28px;
      background: #4566E8;
      filter: blur(4px);
      position: absolute;
      border-radius: 50%;
      z-index: 1;
      top: -65px;
      left: 160px;
    }
    &:after {
      width: 65px;
      height: 65px;
      background: #D280FF;
      filter: blur(6.5px);
      position: absolute;
      border-radius: 50%;
      z-index: 1;
      top: -100px;
      right: 164px;
      content: "";
    }
  }
}

.page-search {
  position: relative;
  padding-top: 24px;
  padding-bottom: 16px;

  .main-find__top {
    display: none;
  }


  @media (min-width: 768px) {
    padding-bottom: 55px;

    .main-find__top {
      display: flex;
    }
  }

  @media (min-width: 992px) {
    .main-find {
      .b-inner {
        &:after {
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);
        }
      }
    }
  }

  @media (min-width: 1200px) {
    &:after {
      width: 28px;
      height: 28px;
      background: #4566E8;
      filter: blur(4px);
      position: absolute;
      border-radius: 50%;
      z-index: 1;
      top: -10px;
      right: 106px;
      content: "";
    }
  }
}

.page-account {
  padding-top: 24px;
  padding-bottom: 24px;
  height: calc(100% - 52px);

  @media (min-width: 768px) {
    height: calc(100% - 80px);
  }

  &__inner,
  .b-inner,
  .row,
  [class*="col-"] {
    height: 100%;
  }

  .title-h2 {
    margin-bottom: 16px;
  }

  .base-text,
  .title-h3 {
    margin-bottom: 8px;
  }

  @media (min-width: 992px) {
    &__inner {
      padding-left: 8px;
    }
  }
}

.list-sidebar {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__item {
    border-bottom:  1px solid #C4DFE6;
    width: 100%;
    padding: 8px 0;

    &:first-child {
      padding: 0 0 8px;
    }

    &:last-child {
      border-bottom: none;

      a {
        color: #D93E5C !important;

        &:before {
          background-image: none;
        }
      }
    }
  }

  .count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px 4px;
    border-radius: 16px;
    background: #FFBD00;
    color: $white;
    @include rem(12);
    font-style: normal;
    font-weight: 300;
    line-height: 116.667%;
    height: 22px;
  }

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    text-decoration: none;
    padding-right: 32px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url(/images/arrow-sidebar.svg);
      background-position: 50%;
      background-repeat: no-repeat;
    }


    &.active {
      color: #003B46 !important;
      padding-right: 0;

      &:before {
        display: none;
      }
    }
  }
}

.tabs-session {
  display: none;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  @media (min-width: 992px) {
    display: flex;
  }

  &__item {
    cursor: pointer;
    height: 40px;
    padding: 8px 0;
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: center;
    color: #66A5AD;
    @include rem(18);
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    align-items: center;
    width: calc(33.33333333% - 8px);

    .count {
      height: 22px;
      border-radius: 8px;
      background: #D93E5C;
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      color: $white;
      @include rem(12);
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    &:before{
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      border-radius: 8px;
    }

    &.active {
      color: #2C7873;
      &:before{
        background: #2C7873;
      }
    }
  }
}

.filter-session {
  display: flex;
  flex-flow: wrap;
  gap: 8px;
  margin-bottom: 16px;

  &__item {
    height: 54px;
    width: calc(50% - 4px);

    &--type {
      display: none;
    }
  }

  select {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    gap: 16px;
    &__item {
      width: calc(50% - 8px);
      &--type {
        display: block;
        width: 100%;
      }
    }
  }
}

.list-session {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  .card-profile {
    &__header {
      gap: 8px;

      &__title {
        color: #2C7873;
        @include rem(15);
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }

      &__date {
        color: #2C7873;
        @include rem(12);
        font-style: normal;
        font-weight: 300;
        line-height: 116.667%;

        &.chosen {
          color: #D93E5C;
        }

        &.history {
          color: #c4c4c4;
        }
      }
    }
  }
}

.list-notifications {
  display: flex;
  flex-direction: column;
  gap: 16px;

}

.b-notifications {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  position: relative;
  gap: 4px;
  padding-bottom: 16px;
  border-bottom: 1px solid #C4DFE6;

  &:last-child {
    border-bottom: none;
  }

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 16px;
    width: 8px;
    height: auto;
    border-radius: 8px;
  }

  &.primary {
    &:before {
      border-radius: 8px;
      background: #6FB98F;
    }
  }

  &.danger {
    &:before {
      border-radius: 8px;
      background: #D93E5C;
    }
  }

  &__title {
    overflow: hidden;
    color: #003B46;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
  }

  &__text {
    color: #66A5AD;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
  }

  &__date {
    color: #B7B7B7;
    @include rem(12);
    font-style: normal;
    font-weight: 500;
    line-height: 116.667%;
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__delete {
    color: #D93E5C !important;
    @include rem(15);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
  }
}

.form-settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.messages-session {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding: 12px 12px 0;

  .card-profile {
    cursor: pointer;
    margin: 0 !important;

    &__header {
      gap: 8px;

      &__title {
        color: #2C7873;
        @include rem(15);
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }

      &__date {
        color: #2C7873;
        @include rem(12);
        font-style: normal;
        font-weight: 300;
        line-height: 116.667%;
      }

      &__name {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .count {
          border-radius: 8px;
          background: #D93E5C;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 22px;
          min-width: 22px;
          padding: 4px;
          color: $white;
          @include rem(12);
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
  }
}

.b-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  .base-text {
    text-align: center;
    color: #2C7873;
  }

  &__title {
    color: #2C7873;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
    text-align: center;
  }
}

.b-work {
  display: flex;
  gap: 16px;
  align-items: center;

  &__text {
    color: #2C7873;
    @include rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }

  .btn-apply {
    width: auto;
  }
}

.image-info {
  display: flex;
  justify-content: center;

  img {
    width: 146px;
    height: 146px;
    object-fit: cover;
  }
}

.coaching-info {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 16px;

  .card-profile {
    border: none !important;

    &__header {
      gap: 8px;

      &__title {
        color: #6FB98F;
        @include rem(15);
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
    }
  }

  &__wrap-btn {
    display: flex;
    gap: 16px;
  }

  @media (min-width: 768px) {
    &__wrap-btn {
      display: flex;
      gap: 10px;
      width: 340px;
    }
  }
}

.coaching-profile {
  display: flex;
  gap: 16px;
  align-items: flex-start;

  &__portrait {
    width: 86px;
    height: 86px;
    border-radius: 16px;
    border: 2px solid #FFF;
    box-shadow: 0 8px 16px 0 rgba(102, 165, 173, 0.32);
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 16px;
    }
  }

  &__inner {
    padding-top: 10px;
  }

  &__name {
    color: #003B46;
    @include rem(18);
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 16px;
  }
}

.coaching-section {
  &__wrap {
    border-top: 1px solid #C4DFE6;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-self: center;

    .b-button__link {
      height: 24px !important;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title-h3 {
      color: #003B46;
      @include rem(16);
      line-height: 18px;
    }

    .case-list {
      margin-top: 8px;

      p {
        margin-top: 8px;
      }
    }
  }

  &__practice {
    color: #2C7873;
    @include rem(16);
    line-height: 18px;
  }

  &__lang {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
      color: #003B46;
      @include rem(16);
      line-height: 18px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
      display: flex;
      gap: 12px;
    }
  }

  &__item {
    color: #003B46;
    @include rem(16);
    line-height: 18px;
  }

  .title-h2 {
    color: #003B46;
    @include rem(18);
    line-height: 24px;
  }

  .base-text {
    margin-bottom: 0;
  }

  &__desc, &__desc > div {
    border-radius: 16px;
    background: #EFFCFF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title-h3 {
      color: #2C7873;
    }

    .base-text {
      color: #003B46;
    }
  }
}

.form-offer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title-h3,
  .title-h2 {
    margin-bottom: 0;
  }
}

.coaching-offer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__title {
    color: #2C7873;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
  }

  &__wrap-btn {
    display: flex;
    gap: 10px;

    a {
      @include rem(15);
      font-style: normal;
      font-weight: 400;
      line-height: 160%;

      &.link-edit {
        color: #66A5AD;
      }

      &.link-remove {
        color: #D93E5C;
      }
    }
  }

  &__price {
    color: #6FB98F;
    @include rem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    span {
      color: #B7B7B7;
    }
  }

  &__desc {
    color: #66A5AD;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 123.077%;
  }
}

.timezone {
  width: 100%;
  height: 108px;
  background-color: #2C7873;
  background-image: url(/images/timezone.svg);
  background-position: 99% 50%;
  background-repeat: no-repeat;
  padding: 16px;
  border-radius: 8px;

  &__title {
    color: #FFBD00;
    @include rem(12);
    font-style: normal;
    font-weight: 300;
    line-height: 116.667%;
  }

  &__utc {
    margin-top: 8px;
    width: 150px;
  }
}

.schedule {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .separator {
    width: 100%;
    height: 1px;
    background: #C4DFE6;
  }

  &-item {
    display: grid;
    gap: 8px;
    grid-template-columns: 80px 1fr 1fr 32px;

    &__single {
      grid-template-columns: 80px 1fr 1fr;
    }
  }

  &__inner{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .title-h3,
  .title-h2,
  .base-text {
    margin-bottom: 0;
  }

  &__wrap {
    display: flex;
    gap: 8px;
    flex-direction: column;

    .btn-cancel,
    .btn-edit {
      height: 40px;
    }
  }

  .btn-delete {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #D93E5C;
    width: 54px;
    height: 54px;
    padding: 0 27px;
    background-image: url(/images/close.svg);
    background-position: 50%;
    background-repeat: no-repeat;
  }

  &__add-file {
    position: relative;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 54px;
      width: 100%;
      padding: 8px 8px 8px 16px;
      border-radius: 8px;
      background: #F8F8F7;
      border: 1px solid #F8F8F7;
      outline: none;
      color: rgba($black, .3);
      @include rem(15);
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        background-image: url(/images/attach-form.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.pay-data-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    display: flex;
    gap: 16px;
  }
}
