.b-schedule-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #003B46;
  @include rem(16);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  & > div {
    display: flex;
    gap: 8px;
  }
}
