.b-multiselect {
  width: 100% !important;
  height: 54px !important;

  .ant-select-selector {
    background-color: transparent !important;
    border-color: #F8F8F7 !important;
    border-radius: 8px !important;
    padding: 22px 16px 8px !important;
    box-shadow: none !important;
    z-index: 1;

    .ant-select-selection-item {
      font-size: 15px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: #313131 !important;
    }
  }

  &.ant-select-status-error {
    .ant-select-selector {
      border-color: #ff4d4f !important;
    }
  }

  .ant-select-selection-overflow-item {
    margin-right: 4px;
  }

  .ant-select-arrow {
    color: #2c7873 !important;
  }

  &.ant-select-focused, &:hover {
    .ant-select-selector {
      border-color: #2c7873 !important;
      box-shadow: none !important;
    }
  }

  &-wrap {
    position: relative;
    width: 100%;
    background-color: #F8F8F7;
    border-radius: 8px;

    &.b-multiselect__active .b-multiselect-label {
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      top: 8px;
    }
  }

  &-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    opacity: .4;
    position: absolute;
    left: 16px;
    top: 15px;
    right: 22px;
    z-index: 1;
    transition: all .1s ease;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
    }
  }
}

.b-select {
  width: 100% !important;
  height: 54px !important;

  .ant-select-selector {
    background-color: transparent !important;
    border-color: #F8F8F7 !important;
    border-radius: 8px !important;
    padding: 22px 16px 8px !important;
    box-shadow: none !important;
    z-index: 1;

    .ant-select-selection-item {
      font-size: 15px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: #313131 !important;
    }
  }

  &.ant-select-status-error {
    .ant-select-selector {
      border-color: #ff4d4f !important;
    }
  }

  .ant-select-arrow {
    color: #2c7873 !important;
  }

  &.ant-select-focused, &:hover {
    .ant-select-selector {
      border-color: #2c7873 !important;
      box-shadow: none !important;
    }
  }

  &-wrap {
    position: relative;
    width: 100%;
    background-color: #F8F8F7;
    border-radius: 8px;

    &.b-select__active .b-select-label {
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      top: 8px;
    }
  }

  &-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    opacity: .4;
    position: absolute;
    left: 16px;
    top: 15px;
    right: 22px;
    z-index: 1;
    transition: all .1s ease;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
    }
  }
}
