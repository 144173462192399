.bb-client {
  margin-top: 140px
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tag-item {
  background: #fff;
  border-radius: 62px;
  margin: 12px 8px;
  color: #5aadcc;
  padding: 16px;
  font-size: 1.5rem;
}

.b2 {
  padding: 120px 0 80px;

  h2 {
    margin-bottom: 40px;
  }
}

.b4 {
  margin-bottom: 30px;

  &-list {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  &-item {
    margin-bottom: 30px;
  }

  &-title {
    font-size: 2rem;
    color: #2c7873;
  }

  &-descr {
    color: #003b46;
    font-size: 1rem;
  }
}

.b4-title, .b5-item {
  margin-bottom: 20px;
}

.b5 {
  position: relative;
  margin-top: -65px;

  &-item {
    background: #fff;
    height: calc(100% - 120px);
    margin-top: 100px;
    position: relative;
    text-align: center;
    padding: 120px 16px 16px;
    border-radius: 16px;
    margin-bottom: 20px;
  }

  &-img {
    height: 204px;
    width: auto;
    top: 0;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &-text {
    font-size: 1.5rem;
    color: #5aadcc;
  }

  h3 {
    margin: 36px 0;
    font-size: 2rem;
    color: #2c7873;
    text-align: center;
  }
}

.b6 {
  margin: 60px 0 40px;

  &-banner {
    background: #ffd213;
    border-radius: 16px;
    padding: 0 32px;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;

    &:after {
      position: absolute;
      right: -70px;
      top: -70px;
      content: "";
      width: 170px;
      height: 170px;
      display: block;
      background: url(/images/white-ball.svg) center center no-repeat;
      background-size: contain;
    }
  }

  &-content {
    width: 45%;
    color: #2C7873;
  }

  &-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  &-descr {
    font-size: 1rem;
  }

  &-img {
    width: 55%;
  }
}
