.b-edu {
  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    &__item {
      padding-top: 12px;
      border-top: 1px solid #C4DFE6;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: flex-start;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }

      & > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
