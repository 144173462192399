.b-schedule {
  &-time {
    padding: 44px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .b-button-link-big {
      font-size: 24px;
      line-height: 32px;
      color: #6FB98F !important;
      font-family: var(--font-comfortaa);
      padding: 0 !important;
      border: none !important;
      text-transform: capitalize;
    }
  }

  &-radio-list {
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &-payment {
    padding: 44px 40px;
    min-height: 300px;
  }
}