@import "_variables";

.main-top {
  padding-top: 16px;
  overflow: hidden;
  max-height: 570px;
  position: relative;

  .b-main-desc {
    min-height: 440px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @media (min-width: 576px) {
    max-height: 600px;
  }

  @media (min-width: 768px) {
    max-height: 700px;
  }

  @media (min-width: 992px) {
    min-height: 516px;
    max-height: 555px;

    &:after {
      content: "";
      width: 28px;
      height: 28px;
      background: #4566E8;
      filter: blur(4px);
      position: absolute;
      border-radius: 50%;
      z-index: 1;
      top: 30px;
      right: 106px;
    }

    &:before {
      content: "";
      width: 65px;
      height: 65px;
      background: #7CE6EE;
      filter: blur(16px);
      position: absolute;
      border-radius: 50%;
      left: calc(((100% - 990px) / 2) / 2);
      top: 32px;
    }
  }

  @media (min-width: 1200px) {
    min-height: 555px;
    &:before {
      left: calc(((100% - 1290px) / 2) / 2);
      top: 32px;
    }
  }


  .b-inner {
    position: relative;

    &:before {
      @media (min-width: 992px) {
        content: "";
        width: 65px;
        height: 65px;
        background: #7CE6EE;
        filter: blur(16px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        left: 500px;
        top: 300px;
      }
      @media (min-width: 1200px) {
        left: 520px;
        top: 310px;
      }
    }

    &:after {
      @media (min-width: 992px) {
        width: 65px;
        height: 65px;
        background: #D280FF;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        top: 110px;
        right: -2px;
        content: "";
      }

      @media (min-width: 1200px) {
        top: 320px;
        right: -2%;
      }

      @media (min-width: 1420px) {
        top: 320px;
        right: -8%;
      }

      @media (min-width: 1770px) {
        top: 320px;
        right: -18%;
      }
    }
  }

  .title-h1 {
    text-align: center;
    margin-bottom: 16px;
    padding-top: 8px;
    position: relative;
    z-index: 1;

    @media (min-width: 992px) {
      max-width: 690px;
      text-align: left;
    }
  }

  &__wrap-text {
    margin-bottom: 24px;
    text-align: center;

    @media (min-width: 992px) {
      max-width: 530px;
      text-align: left;
    }

    @media (min-width: 1200px) {
      max-width: 580px;
    }
    @media (min-width: 1450px) {
      max-width: 690px;
    }
  }

  &__text {
    color: #2C7873;
    @include rem(13);
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;

    @media (min-width: 576px) {
      @include rem(15);
    }

    @media (min-width: 768px) {
      @include rem(18);
    }

    @media (max-width: 1249px) {
      br {
        display: none;
      }
    }
  }

  &__wrap-btn {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    position: relative;
    margin: auto;

    @media (min-width: 992px) {
      gap: 24px;
      justify-content: flex-start;
      margin: 0;
    }

    @media (min-width: 1200px) {
      &:after {
        content: "";
        width: 41px;
        height: 41px;
        background: #FCFF3D;
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        left: -63px;
        bottom: -75px;
      }
    }
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 12px;
    @include rem(14);
    border-radius: 24px;
    gap: 6px;
    color: $white !important;
    font-style: normal;
    font-weight: 700;
    line-height: 133.333%;
    text-decoration: none;

    &--android {
      background: #D280FF;
    }

    &--apple {
      background: #5AADCC;
    }

    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }

    .line {
      background: #D9D9D9;
      width: 1px;
      height: 24px;
    }

    @media (min-width: 430px) {
      height: 56px;
      padding: 16px;
      @include rem(18);
      border-radius: 24px;
      min-width: 191px;
      gap: 8px;
    }

    @media (min-width: 768px) {
      height: 64px;
      padding: 11px 15px 11px 24px;
      @include rem(24);
      border-radius: 62px;
      justify-content: flex-start;
      .line {
        height: 42px;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__img {
    width: 397px;
    height: 403px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    @media (max-width: 991px) {
      &:before {
        content: "";
        position: absolute;
        width: 65px;
        height: 65px;
        background: #7CE6EE;
        filter: blur(16px);
        border-radius: 50%;
        top: 255px;
        z-index: 2;
        left: -20px;
      }

      &:after {
        content: "";
        width: 65px;
        height: 65px;
        background: #D280FF;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        right: -35px;
        top: 25px;
      }
    }

    @media (min-width: 992px) {
      width: 415px;
      height: 421px;
      right: 0;
      top: 90px;
      left: auto;
      position: absolute;
      transform: none;
    }

    @media (min-width: 1200px) {
      top: 0;
      right: 0;
      width: 615px;
      height: 624px;
    }

    @media (min-width: 1420px) {
      width: 615px;
      height: 624px;
      right: -44px;
      top: 0;
    }
  }
}

.main-articles {
  position: relative;
  background: #DFF5FB;
  padding-bottom: 10px;
  margin-bottom: 94px;

  &:before {
    content: "";
    position: absolute;
    background-image: url(/images/wave-top.svg);
    background-position: center;
    height: 80px;
    width: 100%;
    top: -80px;
    overflow: hidden;
    background-size: 893px 87px;

    @media (min-width: 768px) {
      height: 97px;
      width: 100%;
      top: -96px;
      background-size: 992px 97px;
    }

    @media (min-width: 992px) {
      height: 150px;
      width: 100%;
      top: -149px;
      background-size: 1538px 150px;
    }

    @media (min-width: 1200px) {
      height: 187px;
      top: -186px;
      background-size: cover;
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-image: url(/images/wave-2.svg);
    background-position: center;
    height: 16px;
    width: 100%;
    bottom: -15px;
    overflow: hidden;
    left: 0;
    background-size: contain;
    background-color: $white;

    @media (min-width: 768px) {
      height: 80px;
      bottom: -79px;
      width: 100%;
      background-size: 1920px 80px;
    }
  }

  .title-h2 {
    margin-bottom: 16px;
  }
}

.main-popular {
  padding-top: 32px;
  padding-bottom: 16px;

  .title-h2 {
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    gap: 16px;
  }

  @media (max-width: 400px) {
    &__coll {
      width: 100%;
      display: none;
      &:first-child {
        display: block;
      }
    }
  }

  @media (min-width: 576px) {
    &__coll {
      width: calc(50% - 8px);
    }
  }

  @media (min-width: 768px) {
    padding-top: 104px;
    padding-bottom: 24px;

    &__coll {
      width: calc(33.33333333% - 8px);
    }
  }

  @media (min-width: 992px) {
    &__coll {
      width: calc(20% - 8px);
    }
  }

  @media (min-width: 1200px) {
    &__coll {
      width: calc(16.66666667% - 8px);
    }
  }
}

.main-find {
  position: relative;
  overflow: hidden;
  padding-bottom: 25px;

  .b-inner {
    position: relative;

    &:before {

      @media (min-width: 992px) {
        content: "";
        width: 65px;
        height: 65px;
        background: #7CE6EE;
        filter: blur(16px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        top: 379px;
        right: -6%;
      }
      @media (min-width: 1200px) {
        top: 379px;
        right: -10%;
      }
    }

    &:after {
      @media (min-width: 992px) {
        width: 65px;
        height: 65px;
        background: #D280FF;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        bottom: -5px;
        right: -2px;
        content: "";
      }

      @media (min-width: 1200px) {

        right: -2%;
      }
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 16px;
  }

  @media (min-width: 992px) {
    &:before {
      content: "";
      width: 51px;
      height: 51px;
      background: #EC8238;
      filter: blur(6.5px);
      position: absolute;
      border-radius: 50%;
      left: 20px;
      top: 923px;
    }
  }

  @media (min-width: 1200px) {
    &:before {
      left: 126px;
      top: 923px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .open-filter {
    display: flex;
    cursor: pointer;
    width: 24px;
    height: 24px;

    @media (min-width: 992px) {
      display: none;
    }
  }


  .b-filter {
    padding-right: 8px;
    position: relative;

    &__block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #C4DFE6;
    }

    &__description {
      color: #6FB98F;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    @media (min-width: 992px) {
      &:before {
        content: "";
        width: 65px;
        height: 65px;
        background: #D280FF;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        left: -126px;
        top: 300px;
      }

      &:after {
        content: "";
        width: 65px;
        height: 65px;
        background: #F5F944;
        filter: blur(6.5px);
        position: absolute;
        border-radius: 50%;
        left: 51px;
        bottom: -110px;
      }
    }
  }

  &__search {
    width: 100%;
    display: none;
    height: 54px;
    gap: 16px;
    margin-bottom: 16px;

    select,
    input {
      width: 100%;
      height: 100%;
    }

    &__input {
      height: 100%;
      flex: 1 0 0%;
    }

    &__sort {
      width: 200px;
      height: 100%;
    }

    &__language {
      width: 150px;
      height: 100%;
    }

    @media (min-width: 992px) {
      display: flex;
      &__sort {
        width: 120px;
      }

      &__language {
        width: 120px;
      }
    }

    @media (min-width: 1200px) {
      &__sort {
        width: 200px;
      }

      &__language {
        width: 150px;
      }
    }

    .btn-apply {
      width: 97px;
    }
  }
}
