.banner {
  text-align: center;
  margin-bottom: -80px;

  &-faq {
    h1 {
      font-size: 2.93rem;
      margin-top: 120px;
      color: #2C7873;
    }
  }

  &-note {
    font-size: 1.5rem;
    color: #4e7c86;
  }
}

.mb-4 {
  margin-bottom: 1.5rem!important;
}

.store-buttons {
  display: flex;
  margin-bottom: 24px;
}

.store-button {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-decoration: none;
  background: #5AADCC;
  border-radius: 62px;
  padding: 21px 15px 21px 73px;
  margin: 0 12px;
  transition: .3s all ease;
  animation: pulse;
  animation-duration: 2s;
  position: relative;

  &:before {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 24px;
    transform: translate(0, -50%);
  }

  &:after {
    width: 1px;
    height: 42px;
    position: absolute;
    left: 64px;
    background: #fff;
    transform: translate(0, -50%);
  }

  &:after, &:before {
    display: block;
    top: 50%;
    content: "";
  }

  &.phone:before {
    background: url(/images/call-outline-white.svg) center center no-repeat;
    background-size: contain;
  }
}

.mb-5 {
  margin-bottom: 3rem!important;
}

.advice {
  &-item {
    background: #E4F5FA;
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 16px;
    font-size: 1.5rem;

    &--with-img {
      display: flex;
      align-items: center;
    }
  }

  &-img {
    width: 148px;
    min-width: 148px;
    height: auto;
    margin-right: 1rem;
  }

  &-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
}
