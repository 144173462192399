.card-room {
  &__details {
    width: 100%;
    display: grid;
    grid-template-columns: 120px auto;
    gap: 4px 8px;

    div {
      @include rem(13);
      font-weight: 500;
      line-height: 120%;
      color: #2C7873;

      &:nth-child(2n) {
        color: #6FB98F;
      }
    }
  }
}

.b-users-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;

  &__empty {
    color: gray;
  }

  &-item {
    padding: 0 0 16px;
    border-bottom: 1px solid #C4DFE6;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &:last-child {
      border-bottom: none;
      padding: 0;
    }

    & > div {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
}

.b-room-form {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    align-items: center;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .card-detail__apply {
    align-self: flex-start;
  }

  .b-room-switch {
    label {
      margin-right: 24px;
      &:after {
        display: none !important;
      }
    }

    & > div {
      justify-content: space-between;
    }
  }
}

.ant-select-item-option-content {
  span {
    text-transform: capitalize;
  }
}