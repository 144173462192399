.b-agora {
  &__wrap {
    width: 100%;
    height: 716px;
    position: relative;
    overflow: hidden;

    &__single {
      border-radius: 16px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    background:rgba(0,0,0,.3);
    height: 100%;
  }

  &__panel {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 24px 24px;
    gap: 24px;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;

    &_group {
      width: 100%;
      display: flex;
      justify-content: center;
      background: rgba(0, 59, 70, 0.4);
      padding: 16px;
      border-radius: 16px;
      margin-top: 24px;
    }
  }

  &__controls {
    display: inline-flex;
    gap: 30px;
    align-items: center;
  }

  &__control {
    width: 40px !important;
    height: 40px !important;
    background: #fff !important;
    box-shadow: none !important;

    &.ant-btn-dangerous {
      background: #D93E5C !important;
    }
  }

  &__control_big {
    width: 60px !important;
    height: 60px !important;
    background: #fff !important;
    box-shadow: none !important;

    &.ant-btn-dangerous {
      background: #D93E5C !important;
    }
  }

  &__local {
    &_user {
      width: 344px;
      height: 230px;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
    }

    &_base {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      //background: rgba(0, 59, 70, 0.6);
      background: #66A5AD;
      position: absolute;
      display: flex;

      & ~ div, & ~ div *{
        background-color: transparent !important;
      }
    }
  }

  &__call {
    &_avatar {
      background-color: #fff;
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 16px;
      border: 2px solid #FFF;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__remote {
    &_user {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    &_warning {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      color: #fff;
      text-align: center;
    }

    &_base {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #003B46;
      position: absolute;
      display: flex;
    }

    &_groups {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: center;

      & > div {
        border-radius: 16px;
        overflow: hidden;

        video {
          object-fit: contain !important;
        }
      }

      &.gr-1 {
        & > div {
          width: 100%;
        }
      }

      &.gr-2, &.gr-3, &.gr-4 {
        & > div {
          flex: calc((100% - 16px) / 2) 0;
        }
      }

      &.gr-5, &.gr-6, &.gr-7, &.gr-8, &.gr-9 {
        & > div {
          flex: calc((100% - 16px * 2) / 3) 0;
        }
      }

      &.gr-10, &.gr-11, &.gr-12, &.gr-13, &.gr-14, &.gr-15, &.gr-16 {
        & > div {
          flex: calc((100% - 16px * 3) / 4) 0;
        }
      }
    }
  }

  &__video {
    &_remote {
      video {
        object-fit: contain !important;
      }
    }
  }
}
