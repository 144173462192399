.b-datepicker {
  width: 100% !important;
  height: 54px !important;

  &.ant-picker-filled {
    background: transparent !important;
    z-index: 1;
    padding-top: 22px !important;
    padding-left: 16px !important;

    &:hover {
      border-color: #2c7873 !important;
    }

    .ant-picker-input {
      input {
        font-size: 14px !important;
      }
    }
  }

  .ant-picker-suffix {
    margin-top: -20px;
  }

  &-wrap {
    position: relative;
    width: 100%;
    background-color: #F8F8F7;
    border-radius: 8px;

    &.b-datepicker__active .b-datepicker-label {
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      top: 8px;
    }
  }

  &-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    opacity: .3;
    position: absolute;
    left: 16px;
    top: 15px;
    right: 22px;
    z-index: 0;
    transition: all .1s ease;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
    }
  }

  &-popup {
    padding: 16px !important;

    .ant-picker-date-panel {
      padding: 16px 8px !important;
    }

    .ant-picker-header-view {
      color: #2c7873 !important;
    }

    .ant-picker-header {
      border: none !important;

      .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
        display: none !important;
      }
    }

    .ant-picker-cell {
      opacity: 0 !important;
      padding: 0 !important;

      &:not(.ant-picker-cell-disabled) {
        color: #66A5AD !important;

        &:hover {
          .ant-picker-cell-inner {
            color: #6FB98F !important;
            background: transparent !important;
          }
        }
      }

      &-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
        color: #6FB98F !important;
        background: transparent !important;
      }

      &-disabled {
        color: rgba(0, 0, 0, 0.25) !important;

        &::before {
          background: transparent !important;
        }
      }

      &.ant-picker-cell-in-view {
        opacity: 1 !important;
        background: transparent !important;
      }

    }

    .ant-picker-cell-inner::before {
      border: none !important;
    }

    th, td {
      vertical-align: middle !important;
      height: 36px !important;
    }

    th {
      color: #66A5AD !important;
    }
  }
}
