@import "_variables";

.b-header {
  position: relative;
  z-index: 2;

  .b-inner {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    align-items: center;

    @media (max-width: 991px) {
      gap: 24px;
    }
  }

  &__logo {
    width: 166px;
    height: 60px;
    margin-right: auto;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    @media (max-width: 991px) {
      width: 104px;
      height: 38px;
    }
  }

  &__menu-open {
    display: none;
    cursor: pointer;

    @media (max-width: 991px) {
      display: flex;
    }
  }

  &__auth {
    color: #66A5AD !important;
    font-size: 16px !important;
    height: auto !important;
    padding: 0 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-right: 16px;

    &__list {
      display: none;
      align-items: center;
      gap: 16px;
      height: inherit;
      list-style:none;
      margin: 0;
      padding: 0;

      li {
        height: 100%;
      }

      a {
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #66A5AD;
        height: 100%;
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        &.active {
          color: #003B46 !important;
        }
      }

      .text {
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #003B46;
        height: 100%;
        display: inline-flex;
        align-items: center;
      }

      &__line {
        color: #C4C4C4;
        @include rem(16);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      @media (min-width: 992px) {
        display: flex;

        a,
        .text  {
          @include rem(16);
        }
      }
    }
  }
}
