.b-timepicker {
  width: 100% !important;
  height: 54px !important;

  &.ant-picker-filled {
    background: transparent !important;
    z-index: 1;
    padding-top: 22px !important;

    &:hover {
      border-color: #2c7873 !important;
    }

    .ant-picker-input {
      input {
        font-size: 14px !important;
      }
    }
  }

  .ant-picker-suffix {
    margin-top: -20px;
  }

  &-wrap {
    position: relative;
    width: 100%;
    background-color: #F8F8F7;
    border-radius: 8px;

    &.b-timepicker__active .b-timepicker-label {
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      top: 8px;
    }
  }

  &-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    opacity: .3;
    position: absolute;
    left: 16px;
    top: 15px;
    right: 22px;
    z-index: 0;
    transition: all .1s ease;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
    }
  }
}
