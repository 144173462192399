.page-error {
  position: relative;
  padding: 88px 0;

  .b-inner {
    display: flex;
    gap: 50px;
    justify-content: center;

    & > div {
      flex-basis: 50%;
    }
  }

  .error {
    &__image {
      img {
        display: block;
        margin: auto;
        width: 400px;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      text-align: center;
    }

    &__code {
      font-size: 180px;
      line-height: 180px;
      font-weight: bold;
      color: #c4c4c4;
    }

    &__subtitle {
      color: #6FB98F;
    }

    &__button {
      width: 300px;
      margin: 0 auto;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 55px;
  }

  @media (min-width: 992px) {
    .b-inner {

    }
  }

  @media (min-width: 1200px) {

  }
}
