.b-modal__decline {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: stretch;
    margin: 44px 0;
  }

  &__title {
    color: #2C7873;
    @include rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &__button {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
