.b-pagination {
  display: flex;
  gap: 8px;

  .ant-pagination-item {
    margin-inline-end: 0 !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: 8px !important;
    line-height: 38px !important;
    font-family: var(--font-inter) !important;
    font-weight: 400 !important;
    border-color: #2c7873 !important;
    font-size: 16px !important;

    a {
      color: #2c7873 !important;
    }
  }

  .ant-pagination-jump-next {
    margin-inline-end: 0 !important;
    height: 40px !important;
    width: 40px !important;
    line-height: 38px !important;
  }

  .ant-pagination-item-active {
    background: #2c7873 !important;

    a {
      color: #fff !important;
    }
  }

  .ant-pagination-item:not(.ant-pagination-item-active):active,
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: rgba(44, 120, 115, 0.06) !important;
  }
}
