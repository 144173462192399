.b-slider {
  margin-top: -105px;

  h2 {
    margin: 40px 0 20px;
    text-align: center;
  }
}

.slider {
  position: relative;
  padding-bottom: 80px;
  padding-top: 20px;
  text-align: center;
  overflow: hidden;

  &:after {
    content: "";
    background: url(/images/slider-nahd.png) center center no-repeat;
    left: 50%;
    top: 20px;
    width: 522px;
    height: 522px;
    position: absolute;
    -webkit-transform: translate(-50%, -5px);
    -ms-transform: translate(-50%, -5px);
    transform: translate(-50%, -5px);
    pointer-events: none;
  }

  &-item {
    display: inline-flex !important;
    justify-content: center;
  }
}

.waves-top {
  position: relative;
  background: #DFF5FB;
  margin-bottom: 24px;

  &:before {
    content: "";
    position: absolute;
    background-image: url(/images/wave-footer.svg);
    background-position: center bottom;
    background-size: contain;
    background-repeat: repeat-x;
    top: -59px;
    width: 100%;
    height: 59px;
  }
}

.advice-list {
  margin: 24px 0 40px;
}

.advice-item {
  background: #E4F5FA;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 16px;
  font-size: 1.5rem;
}

.advice-item--with-img {
  display: flex;
  align-items: center;

  .advice-img {
    width: 148px;
    min-width: 148px;
    height: auto;
    margin-right: 1rem;
  }

  .advice-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
}

.b3 {
  margin-bottom: 40px;

  &-item {
    background: #e0f3f1;
    height: calc(100% - 120px);
    margin-bottom: 20px;
    margin-top: 100px;
    position: relative;
    text-align: center;
    padding: 120px 16px 16px;
    border-radius: 16px;
  }

  &-img {
    position: absolute;
    height: 204px;
    width: auto;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &-title {
    color: #000;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 14px;
  }

  &-text {
    font-size: 1rem;
  }
}

