.b-calendar {
  padding: 44px 40px !important;

  &-month {
    text-transform: capitalize;
  }

  &-header {
    justify-content: center;
    border-bottom: none !important;
  }

  &-cell {
    span {
      color: #66A5AD;
    }

    &__weekend {
      span {
        color: #FFBD00;
      }
    }
  }

  .ant-picker-body {
    margin-bottom: -42px !important;
  }

  .ant-picker-panel {
    border-top: none !important;
    margin-top: 12px;
  }

  .ant-picker-cell {
    opacity: 0 !important;

    &-disabled {
        &::before {
          background: transparent !important;
        }
    
        span {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }

    &.ant-picker-cell-in-view {
      opacity: 1 !important;
      background: transparent !important;
    }
  }

  th, td {
    vertical-align: middle !important;
    height: 40px !important;
  }

  th {
    color: #66A5AD !important;
  }
}