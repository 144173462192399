@import "_variables";

.b-footer {
  background: #DFF5FB;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;

  &:after {
    content: "";
    position: absolute;
    background-image: url(/images/wave-footer.svg);
    background-position: center;
    height: 16px;
    width: 100%;
    top: -15px;
    overflow: hidden;
    left: 0;
    background-size: contain;

    @media (max-width: 400px) {
      background-size: cover;
    }

    @media (min-width: 768px) {
      height: 80px;
      top: -79px;
      background-size: 1920px 80px;
    }
  }

  &__logo {
    margin-right: auto;
    width: 104px;
    height: 38px;
    display: block;
    text-decoration: none;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__row {
    display: flex;
    gap: 24px;
  }

  &__coll,
  &__coll-2  {
    display: flex;

    gap: 16px;

    &__item {
      display: flex;
      gap: 16px;
    }
  }

  &__social {
    display: flex;
    gap: 16px;
    margin-left: auto;
  }

  &__link {
    display: inline-flex;
    gap: 8px;
    text-decoration: none;
    color: #003B46 !important;
    @include rem(16);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    white-space: nowrap;
  }

  &__text {
    display: inline-flex;
    gap: 8px;
    color: #003B46;
    @include rem(16);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  @media (max-width: 767px) {
    &__logo {
      margin-bottom: 16px;
    }
    &__row {
      flex-direction: column;
      gap: 16px;
    }

    &__coll {
      flex-direction: column;
      &__item {
        flex-direction: column;
        gap: 16px;
      }
    }
  }


  @media (min-width: 768px) {
    padding-bottom: 32px;

    &__row {
      gap: 18px;
    }

    &__coll,
    &__coll-2 {
      gap: 16px 18px;
      flex-direction: column;

      &__item {
        gap: 18px;
      }
    }
  }

  @media (min-width: 992px) {
    &__logo {
      margin-right: auto;
      margin-left: 14px;
      width: 178px;
      height: 64px;
    }

    &__row {
      gap: 24px;
    }

    &__coll,
    &__coll-2 {
      gap: 16px 24px;
      flex-direction: column;

      &__item {
        gap: 24px;
      }
    }
  }
}
